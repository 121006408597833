import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function Faq() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	let language = localStorage.getItem("language");
    // console.log("axios.interceptors.request.language : ", language);

	let faqArr=[];

	if (language === 'en')
	{
		faqArr=[
			,{"index":1,"categori":"학습(앱)","title":"I want to download YAME-Coding.","answer":"YAM-E coding is supports both Android and iOS. <br>It can be installed on smartphones and tablets. <br>Search and install YAM-E Coding. <br>Download and install the Windows version from the YAME Coding download page."}
			,{"index":2,"categori":"학습(앱)","title":"Should YAM-E be connected to the Internet?","answer":"YAM-E is an app that you can't use unless you have an Internet connection. <br>Please check the internet before using it."}
			,{"index":3,"categori":"학습(앱)","title":"What are the minimum specifications for YAM-E coding?","answer":"Hardware: 4GB or more of RAM is required. (On a mobile basis, PCs may need more RAM.) <br> Operating system: Minimum specification IOS 12 version or higher, Android 8.0 version or higher, Windows 10 version or higher"}
			,{"index":4,"categori":"학습(앱)","title":"I want to use social login.","answer":"You can use Google login and Apple login. Each social login is supported relatively acccording to operating systems you use."}
			,{"index":5,"categori":"홈페이지","title":"I want to download member registration manual.","answer":"You can download member registration manual here: <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%95%B1%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%20%EB%B0%8F%20%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to read the document, you also can download the document to your PC. Click the link with right mouse button click, then select [Save As...]."}
			,{"index":6,"categori":"홈페이지","title":"I can't log in to YAM-E after member registration.","answer":"If you finish registering your account, you well receive a verification e-mail. You need to verify your e-mail to log in."}
			,{"index":7,"categori":"홈페이지","title":"I want to download coupon registration and usage manual.","answer":"You can download coupon registration and usage manual here: <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%BF%A0%ED%8F%B0%EB%93%B1%EB%A1%9D%20%EB%B0%A9%EB%B2%95%EA%B3%BC%20%EC%82%AC%EC%9A%A9)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to open the window and see the document and to download it to your PC, right-click the link and click Save As."}
			,{"index":8,"categori":"홈페이지","title":"How do I download the paid payment manual?","answer":"You can download payment manual here: <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%9C%A0%EB%A3%8C%EA%B2%B0%EC%A0%9C%20%EB%B0%A9%EB%B2%95)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to open the window and see the document and to download it to your PC, right-click the link and click Save As."}
			,{"index":9,"categori":"홈페이지","title":"I want to read learning reports for free learners.","answer":"Learning reports are only provided for paid learners."}
			,{"index":10,"categori":"결제","title":"I want to purchase a group memberhip.","answer":"If you have more then 30 learners, you should purchase a group membership. For purchasing a group membership, contact us by e-mail or by phone(contact information is at the bottom of the website)."}
			,{"index":11,"categori":"결제","title":"I have a problem with paymnet. What can I do?","answer":"If you have problems during payment, please contact Inicis directly. If you have problems in using YAM-E after complete payment successfully, please contact us by e-mail or by phone(contact information is at the bottom of the webside)"}		
			,{"index":12,"categori":"기타","title":"I want to directly make my inquiry to and get feedback from WeClover.","answer":"You can make your inquiry from the <a href='/Qna'>'Q&A'</a> after log in, and we will answer your inquiry(You can leave any question as a non-registered member)."}		
			,{"index":13,"categori":"결제","title":"I want to get a refund.","answer":"You can get a refund right away if you didn't use YAM-E coding within 7 days ffrom the date of payment. You can request a refund My page menu."}
		];
	}
	else if (language === 'jp')
		{
			faqArr=[
				,{"index":1,"categori":"학습(앱)","title":"YAM-E codingをダウンロードしたいです。","answer":"YAM-E codingはAndroidとiOSの両方で使用できます。 <br>スマートフォンやタブレットに取り付け可能です。 <br>ヤミコーディングで検索してインストールしてください。 <br>WindowsバージョンはYAME Codingダウンロードページからダウンロードしてインストールしてください。"}
				,{"index":2,"categori":"학습(앱)","title":"YAM-E codingを使うためにはインターネットに接続しなければなりませんか？","answer":"YAM-Ecodingを使用するには、インターネットに接続されている必要があります。 <br>必ずインターネット環境をを確認してからご使用ください。 <br>インターネットにつながらないと使えないアプリです。 <br>必ずインターネットを確認してからご使用ください。"}
				,{"index":3,"categori":"학습(앱)","title":"YAM-E codingの最小仕様はどうなりますか？","answer":"ハードウェア: RAM 4GB 以上のハードウェアを必要とします。(モバイル基準、PCはRAMがさらに必要になる場合があります。) <br>オペレーティングシステム:最小仕様IOS 12バージョン以上、Android 8.0バージョン以上、Windows 10バージョン以上"}
				,{"index":4,"categori":"학습(앱)","title":"簡単ログインは使用機器が決まっていますか？","answer":"簡単ログイン中のグーグルログインはアンドロイドでのみ使用可能で、アップルログインはアップルでのみ使用可能です。 ヤミコーディングアプリを実行する機器に合った簡単ログインを選択してください。"}
				,{"index":5,"categori":"홈페이지","title":"会員登録マニュアルのダウンロードはどうすればいいですか？","answer":"会員登録マニュアルを共有いたします。 ダウンロードしてください。 <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%95%B1%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%20%EB%B0%8F%20%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85)%20Ver%201.0.pdf' download>[ダウンロード]</a><br>をクリックすると、ウィンドウが開き、文書が表示され、PCにダウンロードするには、リンクの右クリックを押して、別の名前で保存するをクリックします。"}
				,{"index":6,"categori":"홈페이지","title":"会員登録後、ログインができません。","answer":"会員登録後に登録されたメールに認証メールが送信されます。 メール認証をしていただかないとログインできません。"}
				,{"index":7,"categori":"홈페이지","title":"クーポンの登録と使い方はどうやってダウンロードしますか？","answer":"クーポン登録と使い方のマニュアルを共有いたします。 ダウンロードしてください。 <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%BF%A0%ED%8F%B0%EB%93%B1%EB%A1%9D%20%EB%B0%A9%EB%B2%95%EA%B3%BC%20%EC%82%AC%EC%9A%A9)%20Ver%201.0.pdf' download>[ダウンロード]</a><br>をクリックすると、ウィンドウが開き、文書が表示され、PCにダウンロードするには、リンクの右クリックを押して、別の名前で保存するをクリックします。"}
				,{"index":8,"categori":"홈페이지","title":"有料決済方法のマニュアルをダウンロードするにはどうすればいいですか？","answer":"有料決済方法のマニュアルを共有いたします。 ダウンロードしてください。 <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%9C%A0%EB%A3%8C%EA%B2%B0%EC%A0%9C%20%EB%B0%A9%EB%B2%95)%20Ver%201.0.pdf' download>[ダウンロード]</a><br>をクリックすると、ウィンドウが開き、文書が表示され、PCにダウンロードするには、リンクの右クリックを押して、別の名前で保存するをクリックします。"}
				,{"index":9,"categori":"홈페이지","title":"無料学習者は学習レポートが出ないのですか？","answer":"学習レポートは有料ユーザー向けです。 有料ユーザーの情報をレポートとして提供します。"}
				,{"index":10,"categori":"결제","title":"団体購入はどうすればいいですか？","answer":"30名以上の学習者は、団体購入を利用する必要があります。 団体購入はホームページ下段のお問い合わせメールや電話でお問い合わせください。"}
				,{"index":11,"categori":"결제","title":"決済に問題が生じました。 どこに問い合わせますか？","answer":"決済エラーが発生しましたら、イニシスにお問い合わせください。 もし、決済成功後にヤミの利用に問題が生じた場合は、ホームページ下段のお問い合わせメールかお電話でお問い合わせください。"}		
				,{"index":12,"categori":"기타","title":"YAM-E coding開発会社に直接問い合わせてフィードバックを受けたいです。","answer":"お問い合わせは、ログインおよびログインでお問い合わせを残すことができます。 お答えいたします。 リンクに移動してください。 <a href='/Qna'> [お問い合わせへショートカット]</a>"}		
				,{"index":13,"categori":"결제","title":"払い戻しはどうやって受けるんですか？","answer":"払い戻しは、お支払いいただいた日から一週間以内にヤミを使用していなければ、すぐに払い戻しできます。 マイページ>決済情報から払い戻ししてください。"}
			];
	}
	else
	{
		faqArr=[
			,{"index":1,"categori":"학습(앱)","title":"YAM-E coding을 다운로드 하고 싶어요.","answer":"YAM-E coding은 Android와 iOS에서 모두 사용할 수 있습니다. <br>스마트폰과 테블릿에 설치 가능합니다. <br>야미코딩으로 검색해서 설치하세요.<br>윈도우 버전은 야미코딩 다운로드 페이지에서 다운로드 받아서 설치하세요."}
			,{"index":2,"categori":"학습(앱)","title":"YAM-E coding을 사용하려면 인터넷에 연결 되어야 하나요?","answer":"YAM-E coding을 사용하려면 인터넷에 연결되어 있어야 합니다. <br>반드시 인터넷에 연결되어 있는지 확인하고 사용해 주십시오. <br>야미는 인터넷에 연결이 되지 않으면 사용할 수 없는 앱입니다. <br>반드시 인터넷을 확인하시고 사용해주세요."}
			,{"index":3,"categori":"학습(앱)","title":"YAM-E coding의 최소사양은 어떻게 되나요?","answer":"하드웨어 : RAM 4GB 이상의 하드웨어를 필요로 합니다. (모바일 기준, PC는 램이 더 필요할 수 있습니다.) <br>운영체제 : 최소사양 IOS 12 버전 이상, Android 8.0 버전 이상, 윈도우 10 버전 이상"}
			,{"index":4,"categori":"학습(앱)","title":"간편 로그인을 사용하고 싶어요.","answer":"구글 로그인은 Android 기기에서만, 애플 로그인은 iOS 기기에서만 사용할 수 있습니다. YAM-E coding 앱을 실행할 기기에 맞는 간편 로그인을 선택해 주십시오."}
			,{"index":5,"categori":"홈페이지","title":"회원 가입 매뉴얼을 다운로드하고 싶어요.","answer":"회언 가입 매뉴얼은 다음 링크에서 다운로드할 수 있습니다. <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%95%B1%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%20%EB%B0%8F%20%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85)%20Ver%201.0.pdf' download> [다운로드]</a> <br>링크를 클릭하면 열리는 창에서 문서를 확인할 수 있습니다. 링크에서 마우스 오른쪽 버튼을 클릭하고 다른 이름으로 저장하기를 눌러 PC에 다운로드 할 수 있습니다."}
			,{"index":6,"categori":"홈페이지","title":"회원 가입 후 로그인이 되지 않아요.","answer":"회원 가입 후에 가입 시 입력한 이메일로 인증 메일이 전송됩니다. 이메일 인증 후 로그인 할 수 있습니다."}
			,{"index":7,"categori":"홈페이지","title":"쿠폰 등록과 사용 방법에 관해 알고 싶어요.","answer":"쿠폰 등록과 사용 방법 매뉴얼은 다음 링크에서 다운로드 할 수 있습니다. <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%BF%A0%ED%8F%B0%EB%93%B1%EB%A1%9D%20%EB%B0%A9%EB%B2%95%EA%B3%BC%20%EC%82%AC%EC%9A%A9)%20Ver%201.0.pdf' download> [다운로드]</a> <br>링크를 클릭하면 열리는 창에서 문서를 확인할 수 있습니다. 링크에서 마우스 오른쪽 버튼을 클릭하고 다른 이름으로 저장하기를 눌러 PC에 다운로드 할 수 있습니다."}
			,{"index":8,"categori":"홈페이지","title":"유료 결제 방법에 관해 알고 싶어요.","answer":"유료 결제 방법 매뉴얼은 다음 링크에서 다운로드 할 수 있습니다. <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%9C%A0%EB%A3%8C%EA%B2%B0%EC%A0%9C%20%EB%B0%A9%EB%B2%95)%20Ver%201.0.pdf' download> [다운로드]</a> <br>링크를 클릭하면 열리는 창에서 문서를 확인할 수 있습니다. 링크에서 마우스 오른쪽 버튼을 클릭하고 다른 이름으로 저장하기를 눌러 PC에 다운로드 할 수 있습니다."}
			,{"index":9,"categori":"홈페이지","title":"무료 학습자의 학습 리포트를 보고 싶어요.","answer":"학습 리포트는 유료 사용자에게만 제공됩니다."}
			,{"index":10,"categori":"결제","title":"단체 구매를 하고 싶어요.","answer":"학습자가 30명 이상이라면 단체 구매를 사용해야 합니다. 홈페이지 하단 문의 메일 혹은 전화로 문의해 주십시오."}
			,{"index":11,"categori":"결제","title":"결제에 문제가 생겼어요. 어디에 문의해야 하나요?","answer":"결제 도중 문제가 발생하면 이니시스에 문의해 주십시오. 결제 성공 이후 YAM-E coding 사용에 문제가 발생하면 홈페이지 하단의 문의 메일 혹은 전화로 문의해 주십시오."}		
			,{"index":12,"categori":"기타","title":"YAM-E coding 개발사에 직접 문의하고 피드백을 받고 싶어요.","answer":"회원 가입 후 문의 메뉴를 통해 직접 문의할 수 있습니다. <a href='/Qna'> [문의사항 바로가기]</a> 비회원으로도 문의를 남길 수 있습니다.>"}		
			,{"index":13,"categori":"결제","title":"환불을 받고 싶어요.","answer":"결제일 기준 7일(영업일 기준) 이내에 YAM-E coding을 사용하지 않았다면 즉시 환불해 드립니다. 마이 페이지 > 결제 정보 메뉴에서 환불을 신청할 수 있습니다."}
		];
	}

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");
			
			//검색창 엔터키 이벤트
			$(`#${styles.faq_input_id}`).on('keydown', function(e)
			{
				if (e.code === 'Enter')
				{
					$(`.${styles.faq_srch_area} button`)[0].click();
				}
			});

			// FAQ 리스트 출력 변수
			let outputHtml = "";
			
			// FAQ 리스트 반복문 (전체 출력)className={styles.faq_tab}
			faqArr.map((x)=> 
			{
				// console.log(x.categori);
				outputHtml += `<li class=${styles.list}>
					<div class=${styles.question_area}>
						<span>Q</span>
						<p>${x.title}</p>
						<img src="assets/faq_arrow.png" alt="arrow"/>
					</div>
					<div class=${styles.answer_area}>
						<p class=${styles.answer}>${x.answer}</p>
					</div>
				</li>`;
			})

			// FAQ 리스트 출력
			// console.log('outputHtml : ', outputHtml);
			$(`.${styles.faq_list_wrap}`).html(outputHtml);

			//카테고리(셀렉트 값) 변경될때마다 해당 질문 뿌려주기
			$(`#${styles.faq_categori_select}`).on('change', function()
			{
				let th=$(this).val();
				$(`#${styles.faq_input_id}`).val("");

				outputHtml = "";
				$(`.${styles.faq_list_wrap}`).html(outputHtml);

				if(th==="결제")
				{
					faqArr.filter((x)=> x.categori==="결제").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="학습(앱)")
				{
					faqArr.filter((x)=> x.categori==="학습(앱)").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="홈페이지")
				{
					faqArr.filter((x)=> x.categori==="홈페이지").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="기타")
				{
					faqArr.filter((x)=> x.categori==="기타").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					faqArr.map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				};
				
				// FAQ 리스트 출력
				// console.log('outputHtml : ', outputHtml);
				$(`.${styles.faq_list_wrap}`).html(outputHtml);

			});


			//질문 영역 클릭시 답변 영역 보이도록, 답변은 하나만 보이도록
			$(document).on('click',`.${styles.faq_list_wrap} li`,function()
			{
				let tg=$(this);
				let activeList=$(`.${styles.faq_list_wrap}`).find(`li.${styles.on}`);
				// console.log('tg :', tg);
				// console.log('activeList :', activeList);
				
				if(activeList.index()===tg.index())
				{
					//현재 활성화된 질문을 또 클릭했을 경우
					$(`.${styles.faq_list_wrap} li`).removeClass(`${styles.on}`);
				}
				else
				{
					//현재 활성화된 질문이 아닌 다른 질문을 클릭한 경우
					$(`.${styles.faq_list_wrap} li`).removeClass(`${styles.on}`);
					tg.addClass(`${styles.on}`);
				};
			});



		});

	}, [$]);


	//FAQ 검색기능
	function faqSearch()
	{
		let keyword=$(`#${styles.faq_input_id}`).val();
		let srchSelect=$(`#${styles.faq_srch_select}`).val();
		// console.log(srchSelect);
		
		if(keyword==="")
		{
			//검색어를 입력하지 않았을때
			// alert('검색어를 입력해주세요.');
			alert(`${t("FQA.alert.string1")}`);
		}
		else
		{
			if(srchSelect===null)
			{
				//검색 카테고리를 선택하지 않았을때
				// alert('검색하실 내용을 선택해주세요.');
				alert(`${t("FQA.alert.string2")}`);
			}
			else if(srchSelect==="1")
			{
				//검색 카테고리가 제목 일때
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
		
				if(faqArr.filter((x)=> x.title.includes(keyword)===true).length>0)
				{
					faqArr.filter((x)=> x.title.includes(keyword)===true).map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					// console.log('검색 결과 없음');
					// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
					outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				};	
		
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			}
			else if(srchSelect==="2")
			{
				//검색 카테고리가 내용 일때
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
				
				if(faqArr.filter((x)=> x.answer.includes(keyword)===true).length>0)
				{
					faqArr.filter((x)=> x.answer.includes(keyword)===true).map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					// console.log('검색 결과 없음');
					// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
					outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				};	
		
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			}
			else
			{
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
				// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
				outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			};
		};

	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		  <div className={styles.faq_top_line_banner}>
			<div className={styles.inner}>
				<span>FAQ</span>
			</div>
		</div>
		<div className={styles.faq_tab}>
			<div className={styles.inner}>
				<span className={styles.faq_tit}>{/* 자주 묻는 질문 */}{t('FQA.title')}</span>
				<p className={styles.faq_txt}>{/* 궁금한 질문을 찾아 클릭하면 답변을 확인할 수 있습니다. */}{t('FQA.string1')}</p>
				<div className={styles.faq_srch_area}>
					<select name="faq_srch_categori" id={styles.faq_srch_select} defaultValue={'DEFAULT'}>
						<option value="DEFAULT" disabled>{/* 선택 */}{t('Common.Select')}</option>
						<option value="1">{/* 제목 */}{t('Common.Title')}</option>
						<option value="2">{/* 내용 */}{t('Common.Content')}</option>
					</select>
					<input type="text" id={styles.faq_input_id}/>
					<button type="button" onClick={faqSearch}>{/* 검색 */}{t('Common.Search')}</button>
				</div>
				<div className={styles.faq_area}>
					<div className={styles.faq_categori_area}>
						<select name="faq_categori" id={styles.faq_categori_select} defaultValue="전체">
							<option value="" disabled>{/* 선택 */}{t('Common.Select')}</option>
							<option value="전체">{/* 전체 */}{t('Common.Total')}</option>
							<option value="결제">{/* 결제 */}{t('FQA.category.cate1')}</option>
							<option value="학습(앱)">{/* 학습(앱) */}{t('FQA.category.cate2')}</option>
							<option value="홈페이지">{/* 홈페이지 */}{t('FQA.category.cate3')}</option>
							<option value="기타">{/* 기타 */}{t('FQA.category.cate4')}</option>
						</select>
					</div>
					<ul className={styles.faq_list_wrap}></ul>
					{/* <ul className={styles.faq_list_pagebtn_area}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#"><img src="assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#"><img src="assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">3</a></li>
						<li><a href="#">4</a></li>
						<li><a href="#">5</a></li>
						<li><a href="#">6</a></li>
						<li><a href="#">7</a></li>
						<li><a href="#">8</a></li>
						<li><a href="#">9</a></li>
						<li><a href="#">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#"><img src="assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#"><img src="assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
				</div>
			</div>
		</div>
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}